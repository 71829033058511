import React from 'react'
import styles from './Sidebar.module.css'
import actionStyle from '../ActionNode/ActionNode.module.css'
import outputStyle from '../OutputNode/OutputNode.module.css'

const onDragStart = (event, node) => {
    const stringNode = JSON.stringify(node)
    event.dataTransfer.setData('application/reactflow', stringNode)
    event.dataTransfer.effectAllowed = 'move'
}

const Sidebar = () => {
    return (
        <div className={`${styles.sidebar} bg-gray-800 text-white`}>
            <div className={styles.description}>You can drag these nodes to the pane on the right.</div>
            <section className={styles.actionNodes}>
                <h2>Start Node</h2>
                <div
                    className={`border border-r-8 p-3 border-blue-500 rounded-r-md px-5, py-2`}
                    onDragStart={(event) => onDragStart(event, {type: 'startNode', name: 'RQST'})}
                    draggable
                >
                    RQST
                </div>
            </section>

            <section className={styles.actionNodes}>
                <h2>Default Nodes</h2>
                <div
                    className={`border border-l-8 border-r-8 p-3 border-gray-600 rounded-md px-5 py-2 my-2`}
                    onDragStart={(event) => onDragStart(event, {type: 'actionNode', name: 'ASD'})}
                    draggable
                >
                    ASD
                </div>

                <div
                    className={`border border-l-8 border-r-8 p-3 border-gray-600 rounded-md px-5 py-2 my-2`}
                    onDragStart={(event) => onDragStart(event, {type: 'actionNode', name: 'RTA'})}
                    draggable
                >
                    RTA
                </div>

                <div
                    className={`border border-l-8 border-r-8 p-3 border-gray-600 rounded-md px-5 py-2 my-2`}
                    onDragStart={(event) => onDragStart(event, {type: 'actionNode', name: 'SFA'})}
                    draggable
                >
                    SFA
                </div>

                <div
                    className={`border border-l-8 border-r-8 p-3 border-gray-600 rounded-md px-5 py-2 my-2`}
                    onDragStart={(event) => onDragStart(event, {type: 'actionNode', name: 'SFR'})}
                    draggable
                >
                    SFR
                </div>
            </section>

            <section className={styles.outputNodes}>
                <h2>End Nodes</h2>
                <div
                    className={`border border-l-8 p-3 border-green-500 rounded-l-md px-5 py-2`}
                    onDragStart={(event) =>
                        onDragStart(event, {
                            type: 'outputNode',
                            name: 'APR',
                        })
                    }
                    draggable
                >
                    APR
                </div>
                <div
                    className={`border border-l-8 p-3 border-red-500 rounded-l-md px-5 py-2 my-3`}
                    onDragStart={(event) =>
                        onDragStart(event, {
                            type: 'outputNode',
                            name: 'REJ',
                        })
                    }
                    draggable
                >
                    REJ
                </div>
            </section>

            <section>
                <h2>Others</h2>
                <div
                    className={`border border-l-8 p-3 border-gray-600 rounded-md px-5 py-2 my-2`}
                    onDragStart={(event) => onDragStart(event, {type: 'actionNode', name: 'Custom Node'})}
                    draggable
                >
                    Custom Node
                </div>
            </section>
        </div>
    )
}

export default Sidebar
