import React, {useRef, useState} from "react";
import ReactFlow, {addEdge, Background, Controls, ReactFlowProvider, removeElements} from "react-flow-renderer";
import Sidebar from "../Sidebar/Sidebar";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "../react-flow-styles.css";
import ButtonEdge from '../ButtonEdge/ButtonEdge'
import {Dialog} from '@headlessui/react';
import LogicNode from "../LogicNode/LogicNode";
import OutputNode from "../OutputNode/OutputNode";
import StartNode from "../StartNode/StartNode";
import ActionNode from "../ActionNode/ActionNode";
import {ExportJsonEditor} from "../ExportJsonEditor/ExportJsonEditor";
import ConnectionLine from "../ConnectionLine/ConnectionLine";
import {useSnapshot} from "valtio";
import state from "../../state";

const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
};

let id = 1;
const getId = () => `node_${id++}_${Math.ceil(Math.random() * 100)}`;

const Workflow = () => {
    const {height} = useWindowDimensions();
    const [reactFlowInstance, setReactFlowInstance] = useState();
    const [elements, setElements] = useState([]);
    const [isOpen, setIsOpen] = useState(true);

    const [workflowName, setWorkflowName] = useState('');
    const [initialState, setInitialState] = useState('');
    const [finalStates, setFinalStates] = useState([]);

    const stateSnapshot = useSnapshot(state)

    const exportJsonEditorModal = useRef(null);

    const onConnect = (params) => {

        params.label = prompt("Please enter event name");

        const sourceHandleUsed = elements.find(
            (elem) => elem.sourceHandle === params.sourceHandle
        );
        //const targetHandleUsed = elements.find((elem) => elem.targetHandle === params.targetHandle)

        if (!sourceHandleUsed /* && !targetHandleUsed*/) {
            setElements((els) => addEdge({...params, type: 'buttonedge'}, els));
        }

    };
    const onElementsRemove = (elementsToRemove) =>
        setElements((els) => removeElements(elementsToRemove, els));
    const onLoad = (_reactFlowInstance) =>
        setReactFlowInstance(_reactFlowInstance);

    const onDrop = (event) => {
        event.preventDefault();

        if (reactFlowInstance) {
            const node = JSON.parse(
                event.dataTransfer.getData("application/reactflow")
            );
            const {type, name} = node;
            const position = reactFlowInstance.project({
                x: event.clientX,
                y: event.clientY - 40
            });
            const newNodeId = getId();
            const newNode = {
                id: newNodeId,
                type,
                name,
                position,
                data: {
                    onDelete: onElementsRemove,
                    label: `${name}`,
                    type,
                    id: newNodeId
                }
            };

            setElements((es) => es.concat(newNode));
        }
    };

    const edgeTypes = {
        buttonedge: ButtonEdge,
    };

    const convertToExportFormat = () => {
        const finalWorkflow = {};
        let formattedData = {nodes: {}, edges: []};
        elements.forEach((elem) => {
            if (elem.id.includes("edge")) {
                formattedData.edges.push({
                    ...elem
                });
            } else {
                formattedData.nodes[`${elem.id}`] = {
                    ...elem
                };
            }
        });
        finalWorkflow['name'] = workflowName;
        finalWorkflow['initialState'] = initialState;
        finalWorkflow['finalStates'] = finalStates;
        finalWorkflow['transitions'] = formattedData.edges.map(edge => {
            return {
                "from": formattedData.nodes[edge.source].name,
                "to": formattedData.nodes[edge.target].name,
                "event": edge.label,
                "guards": [
                    {
                        "expression": `'${state.guards[edge.id]}'.includes(object.role)`
                    }
                ]

            };
        });

        exportJsonEditorModal.current.show({json: JSON.stringify(finalWorkflow, null, 2)})
        // console.log(JSON.stringify(formattedData));
    };

    const exportAsJson = () => {
        convertToExportFormat();
    };

    return (
        <div className='bg-gray-800 h-screen'>
            <div className='w-screen bg-gray-800 flex bg-gray-500 justify-between px-10 py-5 text-white shadow-lg'>
                <h1 className='text-3xl font-bold m-2 text-gray-50'>eRIS Workflow Designer</h1>
                <button className='rounded-md px-5 py-3 bg-green-800 text-white' onClick={exportAsJson}>Export
                    Workflow
                </button>
            </div>

            <ReactFlowProvider>
                <div className={`w-screen bg-gray-800 grid grid-cols-7 h-5/6`}>

                    <div className=' col-span-6 w-screen'>
                        <ReactFlow
                            className='h-screen'
                            elements={elements}
                            onConnect={onConnect}
                            onElementsRemove={onElementsRemove}
                            onLoad={onLoad}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            connectionLineComponent={ConnectionLine}
                            nodeTypes={{
                                logicNode: LogicNode,
                                outputNode: OutputNode,
                                startNode: StartNode,
                                actionNode: ActionNode
                            }}
                            edgeTypes={edgeTypes}
                        >
                            <Background/>
                            {/*<MiniMap/>*/}
                            <Controls/>
                        </ReactFlow>
                    </div>
                    <div className='col-span-1 shadow-lg z-10'>
                        <Sidebar/>
                    </div>
                </div>
            </ReactFlowProvider>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                className="fixed z-10 inset-0 overflow-y-auto"
            >
                <div className="flex items-center justify-center min-h-screen">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"/>

                    <div className="relative bg-white rounded max-w-sm w-screen h-70  bg-gray-800 p-6">
                        <Dialog.Title className="font-bold text-lg s text-white">
                            Create new workflow
                        </Dialog.Title>
                        <hr/>
                        <form className='pt-5 flex flex-col text-white' action="">
                            <div>
                                <label className='block my-1' htmlFor="">Workflow name</label>
                                <input
                                    value={workflowName}
                                    onChange={event => setWorkflowName(event.target.value)}
                                    className='shadow-lg pl-3 w-full py-2 bg-gray-700 rounded-md border-green-500'
                                    placeholder="PIM Approval Workflow"/>
                            </div>

                            <div className='py-5'>
                                <label className='block my-1' htmlFor="">Initial state</label>
                                <input
                                    value={initialState}
                                    onChange={event => setInitialState(event.target.value)}
                                    className='shadow-lg pl-3 w-full py-2 bg-gray-700 rounded-md border-green-500'
                                    placeholder='RQST'/>
                            </div>

                            <div>
                                <label className='block my-1' htmlFor="">Final states</label>
                                <input
                                    value={finalStates.join(',')}
                                    onChange={event => setFinalStates(event.target.value.split(","))}
                                    className='shadow-lg pl-3 w-full py-2 bg-gray-700 rounded-md border-green-500'
                                    placeholder="APR, REJ"/>
                            </div>

                            <input type="hidden" autoFocus="true"/>

                            <button
                                type='button'
                                onClick={() => setIsOpen(false)}
                                className='self-end my-3 py-2 px-5 shadow-md rounded-md bg-green-800 text-white'>Create
                            </button>

                        </form>

                    </div>
                </div>
            </Dialog>

            <ExportJsonEditor ref={exportJsonEditorModal}/>

            <footer className='text-gray-200 text-center'>eRIS Workflow Manager, 2021</footer>

        </div>

    );
};

export default Workflow;
