export default (state) => {
    let sum = 0;

    state.split("").forEach((_, index) => {
        sum += state.charCodeAt(state[index])
    })

    return {bg: colorsBg[sum % 8], border:  colorsBorder[sum % 8]};

}

const colorsBg = [
    "bg-green-500",
    "bg-blue-600",
    "bg-indigo-600",
    "bg-purple-600",
    "bg-pink-600",
    "bg-yellow-800",
    "bg-red-800",
    "bg-grey-800",
]

const colorsBorder = [
    "border-green-500",
    "border-blue-600",
    "border-indigo-600",
    "border-purple-600",
    "border-pink-600",
    "border-yellow-600",
    "border-red-600",
    "border-grey-600",
]
