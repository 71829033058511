import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Dialog } from '@headlessui/react';
import state from '../../state';

const GuardModal = forwardRef(({ edgeId, onRolesSetChanged }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedRoles, setSelectedRoles] = useState(state.guards[edgeId] ? state.guards[edgeId] : []);

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    show() {
      setIsOpen(true);
    },
  }));

  const rolesList = [
    {
      name: 'Lab Team Leader',
      role_code: 'ROLE_LAB_MODERATOR',
    },
    {
      name: 'Secretary Profile',
      role_code: 'ROLE_CLINICAL',
    },
    {
      name: 'Lab Officer',
      role_code: 'ROLE_LAB',
    },
    {
      name: 'Deputy Director General',
      role_code: 'DDGEN',
    },
    {
      name: 'Director General',
      role_code: 'DGEN',
    },
    {
      name: 'Applicant Coordinator',
      role_code: 'APCO',
    },
    {
      name: 'Manufacturer(Sanitizer)',
      role_code: 'ROLE_MAN',
    },
    {
      name: 'Lab Director',
      role_code: 'ROLE_LAB_HEAD',
    },
    {
      name: 'Food Assessor',
      role_code: 'ROLE_FOOD_REVIEWER',
    },
    {
      name: 'Port inspector',
      role_code: 'PINS',
    },
    {
      name: 'Quality Assurance',
      role_code: 'QA',
    },
    {
      name: 'Administrator',
      role_code: 'ADM',
    },
    {
      name: 'Food Safety Reviewer',
      role_code: 'FD_REVIEWER',
    },
    {
      name: 'Registrant',
      role_code: 'REGISTRANT',
    },
    {
      name: 'Super Admin',
      role_code: 'SADM',
    },
    {
      name: 'IP Applicant',
      role_code: 'IPA',
    },
    {
      name: 'Registration Director',
      role_code: 'ROLE_HEAD',
    },
    {
      name: 'Organization Informant',
      role_code: 'ORGINF',
    },
    {
      name: 'Auditor',
      role_code: 'AUD',
    },
    {
      name: 'Customer Service Officer',
      role_code: 'CSO',
    },
    {
      name: 'PIP Applicant',
      role_code: 'PIPA',
    },
    {
      name: 'Data Entry Clerk',
      role_code: 'DEC',
    },
    {
      name: 'Inspector',
      role_code: 'INS',
    },
    {
      name: 'Port Inspector Team Leader',
      role_code: 'PORT_INS_TLD',
    },
    {
      name: 'Port Director',
      role_code: 'PORT_DIR',
    },
    {
      name: 'Inspector Team Leader',
      role_code: 'INTL',
    },
    {
      name: 'Customer Service Director',
      role_code: 'CSD',
    },
    {
      name: 'GMP Coordinator',
      role_code: 'GCO',
    },
    {
      name: 'Customer Service Team Leader',
      role_code: 'CST',
    },
    {
      name: 'Registration Team Leader',
      role_code: 'ROLE_MODERATOR',
    },
    {
      name: 'Dossier Assessor',
      role_code: 'ROLE_REVIEWER',
    },
    {
      name: 'Inspection Director',
      role_code: 'INSD',
    },
    {
      name: 'Legal Team',
      role_code: 'LEGAL',
    },
  ];

  const [roles, setRoles] = useState(rolesList);

  useEffect(() => {
    onRolesSetChanged && onRolesSetChanged(selectedRoles);
  }, [selectedRoles]);

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="relative bg-white rounded max-w-sm w-screen h-70  bg-gray-800 p-6">
          <Dialog.Title className="font-bold text-lg s text-white">Add Guard</Dialog.Title>
          <hr />

          <div className="mt-6 flex flex-col">
            <input
              onChange={(event) => {
                console.log(
                  roles.filter((role) => {
                    console.log(role.name);
                    return role.name.includes(event.target.value);
                  })
                );
                event.target.value !== '' ? setRoles(roles.filter((role) => role.name.toLowerCase().includes(event.target.value.toLowerCase()))): setRoles(rolesList)
              }}
              className="shadow-lg pl-3 w-full py-2 bg-gray-700 rounded-md border-green-500"
              placeholder="Search roles here"
            />
            {roles.map((role) => (
              <label key={role.code} className="inline-flex items-center text-gray-100 my-2">
                <input
                  onChange={(event) => {
                    if (event.target.checked) setSelectedRoles([...selectedRoles, role.role_code]);
                    else setSelectedRoles(selectedRoles.filter((focusedRole) => role.role_code !== focusedRole));
                  }}
                  checked={selectedRoles.includes(role.role_code)}
                  className="text-gray-700 bg-gray-500 w-6 h-6 mr-2 focus:ring-gray-700 outline-none rounded shadow-lg"
                  type="checkbox"
                />
                <div className="ml-5">
                  <p className="font-bold p-0">{role.role_code}</p>
                  <p className="text-xs p-0">{role.name}</p>
                </div>
              </label>
            ))}

            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="block self-end my-3 py-2 px-5 shadow-md rounded-md bg-green-800 text-white"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
});

GuardModal.propTypes = {};
GuardModal.defaultProps = {};

export default GuardModal;
