import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './index.css';

import App from './App'
import { ExportJsonEditor } from './components/ExportJsonEditor/ExportJsonEditor';

const rootElement = document.getElementById('root')
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  rootElement
)
