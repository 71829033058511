import React, {forwardRef, useImperativeHandle, useState} from 'react'
import CodeEditor from '@uiw/react-textarea-code-editor';
import {Dialog} from '@headlessui/react';


export const ExportJsonEditor = forwardRef((prop, ref) => {

    const [code, setCode] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({

        show({json}) {
            setCode(json);
            setIsOpen(true);
        }

    }));

    const handleSaveToPC = jsonData => {
        const blob = new Blob([jsonData], {type: "text/plain"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'workflow.json';
        link.href = url;
        link.click();
    }

    return (

        <Dialog
            as="div"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="fixed z-10 inset-0 overflow-y-auto w-screen h-screen"
        >
            <div className="flex items-center justify-center h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"/>

                <div className="relative flex flex-col rounded w-1/2  bg-gray-800 p-6">
                    <Dialog.Title className="font-bold text-lg text-white">
                        Export Workflow
                    </Dialog.Title>
                    <hr className="my-2"/>

                    <div className="h-96">
                        <textarea
                            value={code}
                            language="json"
                            spellCheck={false}
                            placeholder="Please your workflow."
                            onChange={(evn) => setCode(evn.target.value)}
                            rows="10"
                            cols="30"
                            className="bg-gray-800 text-gray-200 w-full h-96 border-0 outline-none focus:outline-none resize-none"
                            style={{
                                fontSize: 14,
                                overflowY: 'scroll',
                                fontFamily: 'Jetbrains Mono,ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                            }}
                        />
                    </div>

                    <button
                        type='button'
                        onClick={() => {
                           handleSaveToPC(code)
                        }}
                        className='block self-end my-3 py-2 px-5 shadow-md rounded-md bg-green-800 text-white'>Save
                    </button>


                </div>
            </div>
        </Dialog>


    );
});
