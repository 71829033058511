import React, {useRef} from 'react';
import {getBezierPath, getEdgeCenter, getMarkerEnd,} from 'react-flow-renderer';

import './ButtonEdge.module.css';
import GuardModal from "../GuardModal/GuardModal";
import state from "../../state";

const foreignObjectSize = 150;

const onEdgeClick = (evt, id) => {
    evt.stopPropagation();
};

export default function CustomEdge({
                                       id,
                                       sourceX,
                                       sourceY,
                                       targetX,
                                       targetY,
                                       sourcePosition,
                                       targetPosition,
                                       style = {},
                                       data,
                                       label,
                                       arrowHeadType,
                                       markerEndId,
                                   }) {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const guardModal = useRef(null);

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <body>

                <div className="w-50 border border-green-500 rounded-md text-center items-center text-white">
                    <p className='text-xs'>Event : <strong className='text-md font-bold'>{label}</strong></p>
                    <hr className='bg-green-500'/>
                    <div className="flex justify-around">
                        <button onClick={() => alert("Add action trigger")} className={'text-xs'}>Actions</button>
                        <button value={"Hello"} onClick={() => guardModal.current.show()} className={'text-xs'}>Guards
                        </button>

                    </div>
                </div>

                {/*<button*/}
                {/*    className="edgebutton"*/}
                {/*    onClick={(event) => onEdgeClick(event, id)}*/}
                {/*>*/}
                {/*    {label}*/}
                {/*</button>*/}
                </body>
                <GuardModal edgeId={id} ref={guardModal} onRolesSetChanged={(roles) => {
                    state.guards[id] = roles;
                }}/>
            </foreignObject>
        </>
    );
}
