import React, {useState} from 'react'
import {Handle} from 'react-flow-renderer'
import NodeHeader from '../NodeHeader/NodeHeader'
import globalStyles from '../Node.module.css'
import styles from './ActionNode.module.css'
import 'react-phone-input-2/lib/semantic-ui.css'
import ColorCodeGenerator from "../../Utils/ColorCodeGenerator";

const ActionNode = ({data}) => {


    const [handles, setHandles] = useState(Array(3).fill(0));

    const [label, setLabel] = useState(data.label !== "CustomNode"?data.label:"")

    return (
        <div className={globalStyles.node}>
            <div className={`border ${ColorCodeGenerator(label).border} bg-gray-800 text-gray-300 shadow-lg`}>
                <Handle
                    id={`${data.id}`}
                    type="target"
                    position="left"
                    isConnectable
                />
                <NodeHeader label={label} type={data.type} onDelete={data.onDelete} id={data.id}/>

                <div className={`px-10 ${ColorCodeGenerator(label).bg} flex`}>
                    <p className='h-4'/>
                    <Handle
                        id={`${data.id}-${Math.random()}`}
                        type="source"
                        position="right"
                        isConnectable={true}
                        className={`${ColorCodeGenerator(label).bg}`}
                    />
                </div>



                {data.label === 'Custom Node' ? (
                    <div className={styles.body}>
                        <label className={styles.label}>State Name</label>
                        <input className={`nodrag nowheel ${styles.input} text-grey-50`} onChange={event => setLabel(event.target.value)}/>
                    </div>
                ) : null}

            </div>
        </div>
    )
}

export default ActionNode
